import React from 'react';
import { Collapse } from 'reactstrap';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import StringToClassName from "../StringToClassName";
import PreviewCompatibleImage from '../PreviewCompatibleImage';

class SignalsCollapse extends React.Component {
  constructor(props) {
    super(props);
    
    this.toggle = this.toggle.bind(this);
    this.state = {collapse: false};
  }
  
toggle() {
    const curretState = this.state.collapse;
    this.setState({ collapse: !this.state.collapse });
  }
  
  render() {
    const mapItem = this.props.mapItem;
    return (
      
        <div>
          <button className={this.state.collapse ? 'iconButton buttonType--expand expanded' : 'iconButton buttonType--expand closed' } onClick={this.toggle}>See proof points <i></i></button>
          <Collapse isOpen={this.state.collapse}>
            <ResponsiveMasonry columnsCountBreakPoints={{350: 1, 750: 2, 900: 2}}>
              <Masonry gutter="clamp(1rem,2vw,1.5rem)">
                {mapItem.signalsList.map(signal => {
                  return (
                    <div key={signal.id} className={'loopItem type--' + StringToClassName(signal.signalType)}>
                      <div className="loopItem__media">
                        {signal.signalImage ? (
                          <PreviewCompatibleImage imageInfo={signal.signalImage} />
                        ) : null}
                      </div>
                      <div className="loopItem__content">
                        <span className="pill">{signal.signalType}</span>
                        <p>{signal.signalDescription}</p>
                      </div>
                    </div>
                  );
                })}
              </Masonry>
            </ResponsiveMasonry>
          </Collapse>
        </div>
      
    );
  }
}

export default SignalsCollapse