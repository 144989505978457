import React from "react";

const SectionDivider = (props) => {
    const heading = props.heading
    const level = props.level
    const lead = props.lead

    return (
        <div className={`sectionDivider ${level ? "sectionDivider--level" + level : ""}`}>
            <h4 className="sectionHeading">{ heading }</h4>
            {lead ? ( <p className="lead">{ lead }</p> ) : null}
      </div>
    )
}

export default SectionDivider;