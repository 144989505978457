import * as React from "react";
import { useState } from 'react';
import PropTypes from "prop-types";
import PreviewCompatibleImage from '../PreviewCompatibleImage';
import SectionDivider from '../SectionDivider';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import ScrollSpy from "react-scrollspy-navigation";
import StickyBox from "react-sticky-box";
import StringToClassName from "../StringToClassName";
import SignalsCollapse from "./SignalsCollapse";

const ThemeInsightsList = ({ gridItems }) => {
  
  return (
    <div className="themeInsights">

    <div className="themeInsights__header">
      <h3>Insights</h3>
    </div>

    <div className="themeInsights__nav">
      <StickyBox offsetTop={120} offsetBottom={0}>
        <ScrollSpy>
          {gridItems.map((item) => (  
            <a href={'#' + StringToClassName(item.insightTitle)} ref={React.createRef()}>
              <p className="insightNumber">Insight {item.insightNumber}</p>
              <p className="insightTitle">{item.insightTitle}</p>
            </a>
          ))}
        </ScrollSpy>
      </StickyBox>
    
    </div>

    <div className="themeInsights__content">
      {gridItems.map((item) => (
          <div key={item.templateKey} className="themeInsight" id={StringToClassName(item.insightTitle)}>

            <section className="themeSubsection insightIntro">
              <div className="insightIntro__image">
                <div className="insightSticker"><h3>Insight {item.insightNumber}</h3></div>
                {item.insightIntroImage ? (
                  <PreviewCompatibleImage imageInfo={item.insightIntroImage} />
                ) : null}
              </div>
              <div className="insightIntro__content">
                <h2 className="insightTitle">{item.insightTitle}</h2>
                <p className="lead">{item.insightIntro}</p>
                {item.insightIntroImageCaption ? (
                  <p className="caption">{item.insightIntroImageCaption}</p>
                  ) : null
                }
              </div>
            </section>

            <section className="themeSubsection supportingBehaviors">
              {item.behaviorsList.map((behavior) => {
                return (
                  <div key={behavior.id} className="supportingBehavior">
                    <div className="behaviorIntro">
                      <p>{behavior.behaviorIntro}</p>
                    </div>
                    <div className="behaviorSignals loopWrapper">
                      <SignalsCollapse mapItem={behavior} />
                    </div>
                  </div>
                );
              })}
            </section>

            <section className="themeSubsection existingOfferings">
              <SectionDivider heading="Existing Offerings" level="4" />
              <div className="loopWrapper">
                <div className="loop">
                  {item.offerings.map(offering => {
                    return (
                      <div className="loopItem">
                        <div className="loopItem__media">
                          {offering.offeringMedia ? (
                            <PreviewCompatibleImage imageInfo={offering.offeringMedia} />
                          ) : null}
                        </div>
                        <div className="loopItem__content">
                          <p>{offering.offeringDescription}</p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </section>

            <section className="themeSubsection thoughtStarters">
              <SectionDivider heading="How might we" level="4" />
              <div className="loopWrapper">
                <div className="loop">
                  {item.howMightWes.map(item => {
                    return (
                      <div className="loopItem thoughtStarter">
                        <p className="lead">{item.howmightwe}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </section>

            <section className="themeSubsection keyConsiderations">
              <SectionDivider heading="Key considerations" level="3" />
              <div className="loopWrapper">
                <div className="loop">
                  {item.keyConsiderations.map(item => {
                    return (
                      <div className="loopItem thoughtStarter">
                        <p>{item.consideration}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </section>

            
          </div>
        
      ))}
    </div>
  
</div>
  );
}


ThemeInsightsList.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      insightTitle: PropTypes.string,
      insightNumber: PropTypes.string,
      insightIntroImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      insightIntroImageCaption: PropTypes.string,
      templateKey: PropTypes.string,
      behaviorsList: PropTypes.shape({
        behaviorIntro: PropTypes.string,
        signalsList: PropTypes.shape({
          signalDescription: PropTypes.string,
          signalImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
          signalType: PropTypes.string,
        }),
      }),  
      howMightWes: PropTypes.shape({
        howMightWe: PropTypes.string,
      }),  
      keyConsiderations: PropTypes.shape({
        consideration: PropTypes.string,
      }),  
      offerings: PropTypes.shape({
        offeringDescription: PropTypes.string,
        offeringMedia: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      }),  
    })
  ),
};

export default ThemeInsightsList;