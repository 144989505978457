import React from "react";
import PropTypes from "prop-types";
import { kebabCase } from "lodash";
import { Helmet } from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import ThemeInsightsList from "../components/listings/ThemeInsightsList";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import PageNav from "../components/PageNav";
import StringToClassName from "../components/StringToClassName";

// eslint-disable-next-line
export const ThemePostTemplate = ({
  slug,
  contentComponent,
  themeTitle,
  japaneseTitle,
  themeIntro,
  themeImage,
  themeInsights,
  helmet,
}) => {
  const pageNavDestination = '/themes';
  const pageNavLabel = 'Themes';

  return (
      <div className="articleWrapper articleType--theme">
        <PageNav position="top" label={pageNavLabel} destination={pageNavDestination} />
        {helmet || ""}
        <article className="articleContent">
            <header className={`articleHeader ${themeImage ? "has-img" : ""}`}>
                <div className="articleHeader__image">
                    {themeImage ? (
                        <PreviewCompatibleImage imageInfo={themeImage} />
                    ) : null}
                </div>
                <div className="articleHeader__content">
                    <h6>Theme</h6>
                    <h3 className="lead textJapanese">{japaneseTitle}</h3>
                    <h1>{themeTitle}</h1>
                    <p className="lead">{themeIntro}</p>
                </div>
            </header>
            <main className="articleBody">
                <ThemeInsightsList gridItems={themeInsights} />
            </main>
        </article>
        <PageNav position="bottom" label={pageNavLabel} destination={pageNavDestination} />
    </div>
  );
};

ThemePostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  themeTitle: PropTypes.string,
  slug: PropTypes.string,
  japaneseTitle: PropTypes.string,
  themeIntro: PropTypes.string,
  themeId: PropTypes.string,
  themeImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  themeInsights: PropTypes.shape({
    insightTitle: PropTypes.string,
    insightNumber: PropTypes.string,
    insightIntroImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    insightIntroImageCaption: PropTypes.string,
    templateKey: PropTypes.string,
    howMightWes: PropTypes.shape({
      howMightWe: PropTypes.string,
    }),  
    keyConsiderations: PropTypes.shape({
      consideration: PropTypes.string,
    }),  
    offerings: PropTypes.shape({
      offeringMedia: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      offeringDescription: PropTypes.string,
    }),
  }),
  helmet: PropTypes.object,
};


const ThemePost = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <ThemePostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        themeTitle={post.frontmatter.themeTitle}
        japaneseTitle={post.frontmatter.japaneseTitle}
        themeIntro={post.frontmatter.themeIntro}
        themeId={post.frontmatter.themeId}
        themeImage={post.frontmatter.themeImage}
        themeInsights={post.frontmatter.themeInsights}
        behaviorsList={post.frontmatter.themeInsights.behaviorsList}
        slug={post.fields.slug}
        helmet={
          <Helmet titleTemplate="%s | Insight">
            <title>{`${post.frontmatter.themeTitle}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
            <body className={'page--theme theme--' + StringToClassName(post.frontmatter.themeTitle)} />
          </Helmet>
        }
      />
    </Layout>
  );
};

ThemePost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default ThemePost;

export const pageQuery = graphql`
  query ThemePostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        themeTitle
        japaneseTitle
        themeIntro
        themeImage {
            childImageSharp {
                gatsbyImageData(
                  quality: 100
                  layout: FULL_WIDTH
                  aspectRatio: 1
                )
            }
        }
        themeInsights {
            insightTitle
            insightNumber
            insightIntro
            insightIntroImageCaption
            insightIntroImage {
              childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    layout: FULL_WIDTH
                    aspectRatio: 1
                  )
              }
            }
            behaviorsList { 
              behaviorIntro
              signalsList {
                signalDescription
                signalType
                signalImage {
                  childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        layout: FULL_WIDTH
                    )
                  }
                }
              }
            }
            howMightWes {
              howmightwe
            }
            keyConsiderations {
              consideration
            }
            offerings { 
              offeringMedia {
                childImageSharp {
                    gatsbyImageData(
                      quality: 100
                      layout: FULL_WIDTH
                    )
                }
              }
              offeringDescription
            }
        }
      }
    }
  }
`;
