import React from "react";
import { Link, graphql } from 'gatsby'

const PageNav = (props) => {
    const position = props.position
    const label = props.label
    const destination = props.destination

    return (
        <nav className={`pageNav ${position ? "pageNavPosition--" + position : ""}`}>
            <Link to={ destination } className="link--back">{label ? label : "Back"}</Link>
            <a href="#" className="link--backTop">Back to Top</a>
        </nav>
    )
}

export default PageNav;

